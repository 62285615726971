<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
              <a-col :md="8" :sm="24">
                <a-form-item label="用戶昵稱" prop="userName">
                  <a-input v-model="queryParam.userName" placeholder="请输入用戶昵稱" allow-clear/>
                </a-form-item>
              </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="審核狀態" prop="applicationStatus">
                <a-select v-model="queryParam.applicationStatus">
                  <a-select-option :value="0">
                    待审核
                  </a-select-option>
                  <a-select-option :value="1">
                    已付款
                  </a-select-option>
                  <a-select-option :value="2">
                    已拒绝
                  </a-select-option>
                  <a-select-option :value="3">
                    待付款
                  </a-select-option>
                  <a-select-option :value="4">
                    待付款
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店铺名稱" prop="storeName">
                  <a-input v-model="queryParam.storeName" placeholder="请输入店铺名稱" allow-clear/>
                </a-form-item>
              </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查詢</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />

      <!-- 增加修改 -->
      <star-level
        ref="starLevel"
        @ok="getList"
      />

      <!-- 增加修改 -->
      <detail-form
        ref="detailForm"
        @ok="getList"
      />


      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered">

        <img style="width: 48px;height: 48px;" preview="店铺LOGO" :src="record.storeLogo" v-if="record.storeLogo != null && record.storeLogo != ''" slot="storeLogo" slot-scope="text, record">

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-if="record.applicationStatus == 0">
            审核
          </a>
          <a-divider type="vertical"/>
          <a @click="$refs.starLevel.handleUpdate(record, undefined)">
            设置星级
          </a>
           <a-divider type="vertical"/>
          <a @click="$refs.detailForm.handleUpdate(record, undefined)">
            详情
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageStoreCertification, delStoreCertification } from '@/api/platform/storeCertification'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import StarLevel from './modules/StarLevel'
import DetailForm from './modules/DetailForm'

export default {
  name: 'StoreCertification',
  components: {
    CreateForm,
    StarLevel,
    DetailForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      queryParam: {
        merchantType: null,
        certificationType: null,
        legalRealName: null,
        legalMobile: null,
        legalCard: null,
        portraitPlane: null,
        emblemPlane: null,
        businessLicense: null,
        companyName: null,
        socialCredit: null,
        businessScope: null,
        userId: null,
        applicationStatus: null,
        refusalCause: null,
        storeName: null,
        storeLogo: null,
        storeBackground: null,
        typeId: null,
        classId: null,
        integralBail: null,
        integralServe: null,
        storeCountry: null,
        storeProvinceName: null,
        storeCityName: null,
        storeCountyName: null,
        storeDetailedAddress: null,
        storeLongitude: null,
        storeDimensionality: null,
        companyCountry: null,
        companyProvinceName: null,
        companyCityName: null,
        companyCountyName: null,
        companyDetailedAddress: null,
        companyLongitude: null,
        companyDimensionality: null,
        companyAddress: null,
        storeDetail: null,
        openStartTime: null,
        openEndTime: null,
        storeCall: null,
        storePrincipal: null,
        principalCall: null,
        doorPicture: null,
        starLevel: null,
        collectionNum: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '店铺名稱',
          dataIndex: 'storeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺logo',
          dataIndex: 'storeLogo',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "storeLogo"}
        },
        {
          title: '商家類型',
          dataIndex: 'merchantType',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 1) {
              return "线上"
            }
            if (value == 2) {
              return "线下"
            }
          }
        },
        {
          title: '认证類型',
          dataIndex: 'certificationType',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 1) {
              return "公司"
            }
            if (value == 2) {
              return "个体"
            }
          }
        },
        {
          title: '用戶编号',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺行业',
          dataIndex: 'typeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '套餐名稱',
          dataIndex: 'className',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '保证金',
          dataIndex: 'integralBail',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '服务费',
          dataIndex: 'integralServe',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '星级',
          dataIndex: 'starLevel',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 1) {
              return "一星"
            }
            if (value == 2) {
              return "二星"
            }
            if (value == 3) {
              return "三星"
            }
            if (value == 4) {
              return "四星"
            }
            if (value == 5) {
              return "五星"
            }
          }
        },
        {
          title: '申请狀態',
          dataIndex: 'applicationStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 0) {
              return "审核中"
            }
            if (value == 1) {
              return "已付款"
            }
            if (value == 2) {
              return "已拒绝"
            }
            if (value == 3) {
              return "已过期"
            }
            if (value == 4) {
              return "待付款"
            }
          }
        },
        {
          title: '拒绝原因',
          dataIndex: 'refusalCause',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查詢店铺-商家认证列表 */
    getList () {
      this.loading = true
     pageStoreCertification(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        merchantType: undefined,
        certificationType: undefined,
        legalRealName: undefined,
        legalMobile: undefined,
        legalCard: undefined,
        portraitPlane: undefined,
        emblemPlane: undefined,
        businessLicense: undefined,
        companyName: undefined,
        socialCredit: undefined,
        businessScope: undefined,
        userId: undefined,
        applicationStatus: undefined,
        refusalCause: undefined,
        storeName: undefined,
        storeLogo: undefined,
        storeBackground: undefined,
        typeId: undefined,
        classId: undefined,
        integralBail: undefined,
        integralServe: undefined,
        storeCountry: undefined,
        storeProvinceName: undefined,
        storeCityName: undefined,
        storeCountyName: undefined,
        storeDetailedAddress: undefined,
        storeLongitude: undefined,
        storeDimensionality: undefined,
        companyCountry: undefined,
        companyProvinceName: undefined,
        companyCityName: undefined,
        companyCountyName: undefined,
        companyDetailedAddress: undefined,
        companyLongitude: undefined,
        companyDimensionality: undefined,
        companyAddress: undefined,
        storeDetail: undefined,
        openStartTime: undefined,
        openEndTime: undefined,
        storeCall: undefined,
        storePrincipal: undefined,
        principalCall: undefined,
        doorPicture: undefined,
        starLevel: undefined,
        collectionNum: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 刪除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认刪除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delStoreCertification(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '刪除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    /** 導出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/store-certification/export', {
            ...that.queryParam
          }, `店铺-商家认证_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
